/* eslint-disable eqeqeq */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import {
  Box,
  Stack,
  AppBar,
  Menu,
  Toolbar,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  MenuItem
} from '@material-ui/core';
import { Alert, AlertTitle } from '@mui/material';
// hooks
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../redux/store';
import { getRestaurant, updateRestaurantShopkeeper } from '../../redux/slices/restaurant';
import { getProductList } from '../../redux/slices/product';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from '../../hooks/useAuth';

// components
import { MHidden, MIconButton } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import YayButton from '../../components/YayButton';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

function verifyIfRestaurantCanBeOpen(restaurant, productsTotal) {
  const result = []
  if (restaurant.restaurantFee?.length <= 0 && restaurant.restaurantFeeKm?.length <= 0) result.push("O restaurante precisa ter pelo menos um bairro ou faixa de atendimento ativo para ser aberto")
  if (restaurant.opening?.length <= 0) result.push("O restaurante precisar ter pelo menos um horário de funcionamento cadastrado para ser aberto")
  if (productsTotal <= 0) result.push("O restaurante precisar ter pelo menos um produto cadastrado para ser aberto")
  if (restaurant.presencialPay <= 0 && !restaurant.onlinePay?.cc && !restaurant.onlinePay?.pix?.status && !restaurant.onlinePay?.picpay?.status) result.push("O restaurante precisa ter pelo menos uma forma de pagamento cadastrada para ser aberto")

  return result
}

export default function DashboardNavbar({ onOpenSidebar }) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [pendencies, setPendencies] = useState([])
  const { isCollapse } = useCollapseDrawer();
  const { loginLicUser, user } = useAuth();
  const dispatch = useDispatch();
  const { restaurant, brothersList } = useSelector((state) => state.restaurant);
  const { productsTotal } = useSelector((state) => state.product);
  let aux = false;
  if (restaurant && restaurant.statusOpen === true) {
    aux = true;
  } else if (restaurant && restaurant.statusOpen === false) {
    aux = false;
  }
  const [checked, setChecked] = useState(aux);

  const handleOpen = () => {
    setOpen(true);
  };

  const changeEst = async (idRest) => {
    try {
      await loginLicUser(idRest);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(getRestaurant());
    dispatch(getProductList());
    setChecked(aux);
  }, [dispatch, aux]);

  useEffect(() => {
    if (restaurant) {
      const pendenciesList = verifyIfRestaurantCanBeOpen(restaurant, productsTotal)
      if (pendenciesList.length > 0) setPendencies(pendenciesList)
    }
  }, [restaurant])

  const changeStatus = (event) => {
    const pendenciesList = verifyIfRestaurantCanBeOpen(restaurant, productsTotal)
    if (pendenciesList.length > 0) return setPendencies(pendenciesList)
    setChecked(event.target.checked);
    dispatch(updateRestaurantShopkeeper({ statusOpen: !!event.target.checked }));
  };

  const goToManage = () => {
    localStorage.setItem('accessToken', localStorage.getItem('accessTokenLic'));
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessTokenLic')}`;
    localStorage.removeItem('accessTokenLic');
    window.location.href = PATH_DASHBOARD.restaurant.list;
  };

  const goToAdm = () => {
    localStorage.setItem('accessToken', localStorage.getItem('accessTokenAdm'));
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessTokenAdm')}`;
    localStorage.removeItem('accessTokenAdm');
    window.location.href = PATH_DASHBOARD.licensed.list;
  };

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Box sx={{ flexGrow: 1 }} />
        {brothersList.length > 0 && user.type === 5 ? (
          <>
            <>
              <MIconButton ref={anchorRef} size="large" onClick={handleOpen}>
                <Icon icon="material-symbols:change-circle" width={40} height={40} />
              </MIconButton>
            </>
            <Menu
              anchorEl={anchorRef.current}
              open={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {brothersList
                .filter((brother) => brother._id !== user.restaurant._id)
                .map((elem) => (
                  <MenuItem key={elem._id} onClick={() => changeEst(elem._id)}>
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      {elem.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </>
        ) : (
          <></>
        )}



        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>

        <YayButton />


          {localStorage.getItem('accessTokenAdm') ? <Button onClick={() => goToAdm()}>Voltar para administrador</Button> : ''}
          {localStorage.getItem('accessTokenLic') &&
            localStorage.getItem('typeUser') ? (
            <Button onClick={() => goToManage()}>Voltar para gerencial</Button>
          ) : (
            ''
          )}

          {user.restaurant && !restaurant?.autoOpen ? (
            <FormControlLabel
              control={<Switch checked={checked} onChange={(e) => changeStatus(e)} />}
              label={checked ? 'Aberto' : 'Fechado'}
              sx={{ color: 'black' }}
            />
          ) : (
            ''
          )}
          {pendencies.length > 0 && <Alert onClick={() => setPendencies([])} sx={{ position: 'absolute', top: '1em', right: '1em', zIndex: 1 }} severity="error">
            <AlertTitle style={{ textAlign: 'left' }}>Você possui pendências</AlertTitle>
            {pendencies.map((p) => (
              <li>{p}</li>
            ))}
          </Alert>}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
