export function convertPay(e) {
  if (e === "debit") {
    return "Débito"
  }
  if (e === "credit") {
    return "Crédito"
  }
  if (e === "pix") {
    return "Pix presencial"
  }
  if (e === "money") {
    return "Dinheiro"
  }
  if (e === "pixOnline") {
    return "Pix on-line"
  }
  if (e === "creditOnline") {
    return "Crédito on-line"
  }
  if (e === "nupay") {
    return "NuPay"
  }
}

export function greetingMessage() {
  const h = new Date().toLocaleTimeString('pt-BR', {
    hour: 'numeric',
    hour12: false
  });
  if (h <= 5) return 'Boa madrugada';
  if (h < 12) return 'Bom dia';
  if (h < 18) return 'Boa tarde';
  return 'Boa noite';
}

