import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  campaigns: [],
  campaignList: [],
  campaignTemplateList: [],
  responsePost: null
};

const slice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCampaignsSuccess(state, action) {
      state.isLoading = false;
      state.campaigns = action.payload;
    },

    deleteCampaign(state, action) {
      const deleteCampaign = filter(state.campaignList, (campaign) => campaign._id !== action.payload);
      console.log(deleteCampaign);
      state.campaignList = deleteCampaign;
    },

    deleteCampaignTemplate(state, action) {
      const deleteCampaign = filter(state.campaignTemplateList, (campaign) => campaign._id !== action.payload);
      state.campaignTemplateList = deleteCampaign;
    },

    getCampaignListSuccess(state, action) {
      state.isLoading = false;
      state.campaignList = action.payload;
    },

    getCampaignTemplateListSuccess(state, action) {
      state.isLoading = false;
      state.campaignTemplateList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getCampaignList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/campaigns`);
      dispatch(slice.actions.getCampaignListSuccess(response.data.campaigns));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCampaignListRes(city) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/campaigns-res`, {city});
      dispatch(slice.actions.getCampaignListSuccess(response.data.campaigns));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCampaignTemplateList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/campaigns-templates`);
      dispatch(slice.actions.getCampaignTemplateListSuccess(response.data.campaigns));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addCampaignByTemplate(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/campaigns-templates`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function storeCampaign(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/campaigns`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateCampaign(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/campaigns`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCampaign(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/campaigns/${newId}`);
      dispatch(slice.actions.deleteCampaign(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function deleteCampaignTemplate(couponId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/campaigns/${couponId}`);
      dispatch(slice.actions.deleteCampaignTemplate(couponId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
