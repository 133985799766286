import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import restaurantReducer from './slices/restaurant';
import onlinePaymentReducer from './slices/onlinePayment';
import shopkeeperReducer from './slices/shopkeeper';
import licensedReducer from './slices/licensed';
import productReducer from './slices/product';
import categoryReducer from './slices/category';
import productCategoryReducer from './slices/productCategory';
import contentCategoryReducer from './slices/contentCategory';
import marketingCategoryReducer from './slices/marketingCategory';
import districtReducer from './slices/district';
import requestReducer from './slices/request';
import couponReducer from './slices/coupon';
import fidelityReducer from './slices/fidelity';
import reportReducer from './slices/report';
import customerReducer from './slices/customer';
import logReducer from './slices/log';
import contentReducer from './slices/content';
import contractReducer from './slices/contract';
import deliveryManReducer from './slices/deliveryMan';
import dashboardLicReducer from './slices/dashboardLic';
import dashboardAdmReducer from './slices/dashboardAdm';
import paymentReducer from './slices/payment';
import pushReducer from './slices/push';
import attendanceReducer from './slices/attendance';
import bannerReducer from './slices/banner';
import configReducer from './slices/config';
import whatsappReducer from './slices/whatsapp';
import insightsReducer from './slices/insights';
import chatReducer from './slices/chat';
import marketingReducer from './slices/marketing';
import campaignsReducer from './slices/campaigns';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  user: userReducer,
  campaigns: campaignsReducer,
  licensed: licensedReducer,
  restaurant: restaurantReducer,
  onlinePayment: onlinePaymentReducer,
  shopkeeper: shopkeeperReducer,
  marketing: marketingReducer,
  district: districtReducer,
  category: categoryReducer,
  request: requestReducer,
  coupon: couponReducer,
  fidelity: fidelityReducer,
  report: reportReducer,
  marketingCategory: marketingCategoryReducer,
  productCategory: productCategoryReducer,
  customer: customerReducer,
  log: logReducer,
  deliveryMan: deliveryManReducer,
  contentCategory: contentCategoryReducer,
  content: contentReducer,
  contract: contractReducer,
  dashboardLic: dashboardLicReducer,
  dashboardAdm: dashboardAdmReducer,
  paymentAdm: paymentReducer,
  push: pushReducer,
  banner: bannerReducer,
  attendance: attendanceReducer,
  chat: chatReducer,
  config: configReducer,
  whatsApp: whatsappReducer,
  product: persistReducer(productPersistConfig, productReducer),
  insights: insightsReducer
});

export { rootPersistConfig, rootReducer };
