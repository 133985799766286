import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from 'axios';
import { isValidToken, setSession, setSessionUser, setSessionLic } from '../utils/jwt';
import { api } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  loginLicUser: () => Promise.resolve(),
  loginAdmLic: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`${api}/perfil`);
          const { user } = response.data;
          window.localStorage.setItem('typeUser', user?.type);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(`${api}/login`, {
      email,
      password
    });
    const { token, user } = response.data;
    window.localStorage.setItem('codId', user._id);
    window.localStorage.setItem('typeUser', user?.type);
    window.localStorage.setItem('momentLogin', Date.now());
    setSession(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const loginLicUser = async (idRest) => {
    const response = await axios.post(`${api}/login-lic-user`, {
      idRest
    });
    const { token, user } = response.data;
    setSessionUser(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };
  const loginAdmLic = async (idLic) => {
    const response = await axios.post(`${api}/login-adm-lic`, {
      idLic
    });
    const { token, user } = response.data;
    setSessionLic(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    localStorage.removeItem('accessTokenAdm');
    localStorage.removeItem('accessTokenLic');
    dispatch({ type: 'LOGOUT' });
  };

  const firstAccess = async (email) => {
    const response = await axios.post(`${api}/first-access`, { email });
    return response.data.user;
  };

  const resetPassword = async (email) => {
    const response = await axios.post(`${api}/recovery-pass`, { email });
    return response;
  };

  const resetPassword2 = async (values) => {
    const response = await axios.put(`${api}/recovery-pass`, values);
    return response;
  };

  const firstPassword = async (values) => {
    const response = await axios.post(`${api}/first-password`, values);
    return response;
  };

  const verifyCodeRecovery = async (code) => {
    const response = await axios.get(`${api}/recovery-pass/${code}`);
    return response.data.user;
  };

  const updateProfile = async () => {
    await axios.get(`${api}/perfil`).then((response) => {
      const { user } = response.data;
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    });
  };

  const updateProfileApi = async (data) => {
    const response = await axios.put(`${api}/perfil`, data);
    const { user } = response.data;
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
    return response;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        loginLicUser,
        loginAdmLic,
        logout,
        register,
        resetPassword,
        firstAccess,
        firstPassword,
        updateProfile,
        updateProfileApi,
        verifyCodeRecovery,
        resetPassword2
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
