import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  updateLoading: false,
  error: false,
  products: [],
  productList: [],
  product: {},
  productsModel: [],
  categoriesModel: [],
  responsePost: null
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startUpdateLoading(state) {
      state.updateLoading = true;
    },

    stopUpdateLoading(state) {
      state.updateLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    deleteProduct(state, action) {
      const deleteProduct = filter(state.productList, (category) => category._id !== action.payload);
      state.productList = deleteProduct;
    },

    updateProductReload(state, action) {
      const newState = state.productList.map((obj) => {
        if (obj._id === action.payload._id) {
          return { ...obj, ...action.payload };
        }

        return obj;
      });
      state.productList = newState;
    },

    getProductListSuccess(state, action) {
      state.isLoading = false;
      state.productList = action.payload.docs;
      state.productsTotal = action.payload.total;
    },

    getProductModelListSuccess(state, action) {
      state.isLoading = false;
      state.productsModel = action.payload;
    },

    getCategoriesModelSuccess(state, action) {
      state.isLoading = false;
      state.categoriesModel = action.payload;
    },

    getProduct(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getProductList(restaurant = '', limit = 15, page = 1, search = '') {
  // let filter = `?limit=${limit}&page=${page}&search=${search}`;
  let filter = '';
  if (restaurant) filter += `?parent=${restaurant}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product${filter}`);
      dispatch(slice.actions.stopUpdateLoading())
      dispatch(
        slice.actions.getProductListSuccess({
          docs: response.data.products.docs,
          total: response.data.products.totalDocs,
          pagesTotal: response.data.products.totalPages,
          actualPage: response.data.products.page
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProductOrder(values) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`${api}/product-order`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getProductModelList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product-model`);
      dispatch(slice.actions.getProductModelListSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductModelListShop(page, category = '', search = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product-model-shop?page=${page}&category=${category}&search=${search}`);
      dispatch(slice.actions.getProductModelListSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategoriesProductModel() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/categories-model`);
      dispatch(slice.actions.getCategoriesModelSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product/${id}`);
      dispatch(slice.actions.getProduct(response.data.product));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeProduct(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/product`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateProduct(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startUpdateLoading());
    try {
      const response = await axios.put(`${api}/product`, values);
      dispatch(slice.actions.getProduct({}));
      dispatch(slice.actions.updateProductReload(values));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteProduct(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/product/${newId}`);
      dispatch(slice.actions.deleteProduct(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function duplicateProduct(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product-duplicate/${newId}`);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
