import { withStyles } from '@material-ui/styles';

// ----------------------------------------------------------------------

const GlobalStyles = withStyles((theme) => ({
  '@global': {
    '@font-face': [
      {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 300,
        src: `url('/fonts/Poppins-Light.ttf') format('truetype')`
      },
      {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        src: `url('/fonts/Poppins-Regular.ttf') format('truetype')`
      },
      {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        src: `url('/fonts/Poppins-Bold.ttf') format('truetype')`
      },
      {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        src: `url('/fonts/Poppins-ExtraBold.ttf') format('truetype')`
      },
      {
        fontFamily: 'Museo',
        fontStyle: 'normal',
        fontWeight: 100,
        src: `url('/fonts/Museo100-Regular.otf') format('opentype')`
      },
      {
        fontFamily: 'Museo',
        fontStyle: 'normal',
        fontWeight: 300,
        src: `url('/fonts/Museo300-Regular.otf') format('opentype')`
      },
      {
        fontFamily: 'Museo',
        fontStyle: 'normal',
        fontWeight: 500,
        src: `url('/fonts/Museo500-Regular.otf') format('opentype')`
      },
      {
        fontFamily: 'Museo',
        fontStyle: 'normal',
        fontWeight: 700,
        src: `url('/fonts/Museo700-Regular.otf') format('opentype')`
      },
      {
        fontFamily: 'Museo',
        fontStyle: 'normal',
        fontWeight: 900,
        src: `url('/fonts/Museo900-Regular.otf') format('opentype')`
      }
    ],
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    html: {
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch'
    },
    body: {
      width: '100%',
      height: '100%'
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled }
    },
    a: { color: theme.palette.primary.main },
    img: { display: 'block', maxWidth: '100%' },

    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(5px)',
      filter: 'blur(5px)',
      transition: 'filter 400ms, -webkit-filter 400ms'
    },
    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)'
    },
    '.buttonFloat': {
      position: 'fixed !important',
      bottom: '20px'
    },
    '.buttonFloatTop': {
      position: 'fixed !important',
      top: '50px',
      zIndex: '99999',
      padding: '20px 30px !important',
      backgroundColor: 'white !important'
    },
    '.radioText': {
      lineHeight: '32px'
    },
    '.radioText select': {
      padding: '5px 15px 5px 10px'
    },
    '.radioInput': {
      width: '50px',
      margin: '0px 4px !important'
    },
    '.radioInput input': {
      textAlign: 'center'
    },
    '.ql-video': {
      maxWidth: '100%',
      width: '600px',
      height: '350px'
    },
    '.videoHelp .MuiDialog-paper': {
      background: 'none',
      boxShadow: 'none',
      borderRadius: '0 !important'
    },
    '.popup': {
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      zIndex: '99999',
      paddingTop: '40px',
    },
    '.popup.black': {
      backgroundColor: 'rgba(0, 0, 0, 0.9)'
    },
    '.popup img': {
      maxWidth: '50%',
      margin: '0 auto'
    },
    '.popup iframe': {
      maxWidth: '100% !important',
    },
    '.closePopup': {
      textAlign: 'center',
      position: 'absolute',
      right: '20px',
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      width: '40px',
      height: '40px',
      fontSize: '47px',
      lineHeight: '30px',
      borderRadius: '100%',
      cursor: 'pointer'
    },

    '#__talkjs_launcher.closed': {
      borderRadius: '0',
      backgroundSize: 'cover',
      boxShadow: 'none',
      background: 'url(/static/chat.png)'
    },
    '#__talkjs_launcher.open': {
      backgroundColor: '#e8b804'
    },
    '.requestsModal': {
       padding: '20px 20px',
       width: '500px'
    },
    '.powerBiEmbled':{
      width: '100%',
      height: '800px',
    },
    '.powerBiEmbled > iframe':{
      border: 'none',
    },
    '.divOnHover': {
      backgroundColor: '#f3f3f3'
    },
  }
}))(() => null);

export default GlobalStyles;
