import { useEffect } from 'react';
import { paramCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getRestaurantInfo } from '../../redux/slices/restaurant';
import { getMunicipios } from '../../redux/slices/licensed';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
import useAuth from '../../hooks/useAuth';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import RestaurantNewForm from '../../components/_dashboard/restaurant/RestaurantNewForm';
import DeliveryManNewFormUnauth from '../../components/_dashboard/deliveryMan/DeliveryManNewFormUnauth';
import Logo from '../../components/Logo';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  flexDirection: 'column',
  paddingTop: '2rem'
}));
// ----------------------------------------------------------------------

export default function DeliveryManCreateUnauth() {
  const { themeStretch } = useSettings();

  return (
    <RootStyle title="Solicitar cadastro de entregador | Jão Delivery">
      <Logo />
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <h1 style={{padding: '0.5rem', textAlign: 'center' }}>Solicitar cadastro de entregador:</h1>
        <DeliveryManNewFormUnauth />
        <br />
      </Container>
    </RootStyle>
  );
}
