import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
import { getRequestsReportList } from './request';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  reportList: [],
  reportListLate: [],
  report: [],
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateReportReload(state, action) {
      const newState = state.reportList.map((obj) => {
        if (obj._id === action.payload._id) {
          return { ...obj, ...action.payload };
        }

        return obj;
      });
      state.reportList = newState;
    },

    getReportListSuccess(state, action) {
      state.isLoading = false;
      state.reportList = action.payload;
    },

    getReportListLate(state, action) {
      state.isLoading = false;
      state.reportListLate = action.payload;
    },

    getReportSuccess(state, action) {
      state.isLoading = false;
      state.report = action.payload.result;
      state.newReport = action.payload.newReport
    }
  }
});

// Reducer
export default slice.reducer;

export function getReportListPaginated(page, month, year, status, query, filterCitiesObject) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/reports?page=${page}&month=${month}&year=${year}&status=${status}&licensedCities=${filterCitiesObject}&query=${query}`);
      dispatch(slice.actions.getReportListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReportList(q = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/reports${q ? `?q=${q}` : ''}`);
      if (q === 'not_pay') {
        dispatch(slice.actions.getReportListLate(response.data.reports));
      } else {
        dispatch(slice.actions.getReportListSuccess(response.data.reports));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReport(rest, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/reports/${rest}/${id}`);
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateReport(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/reports`, values);
      dispatch(slice.actions.updateReportReload(values));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
