import React, { useEffect } from 'react';

const YayButton = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.yayforms.link/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <button 
      data-yf-popup="J7dkqPV" 
      data-yf-size="100" 
      style={{
        all: 'unset',
        fontFamily: 'Helvetica, Arial, sans-serif',
        display: 'inline-block',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        padding: '0 20px',
        fontWeight: 'bold',
        height: '35px',
        cursor: 'pointer',
        lineHeight: '35px',
        textAlign: 'center',
        margin: '0',
        textDecoration: 'none',
        backgroundColor: '#000000',
        borderRadius: '6px'
      }}
    >
      Sugerir funcionalidade
    </button>
  );
};

export default YayButton;
